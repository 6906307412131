import { t } from 'i18next';

const getTranslation = (key: string, params?: Record<string, string | boolean | null>) => {
  const translation = t(key, params);

  if (key.includes(translation)) return undefined;

  return translation;
};

export default getTranslation;
