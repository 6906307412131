import { RequestConnectionType } from 'src/types/bizcuitApi';

// URL that Orchestrator redirects to when something unexpected happens
export const ORCHESTRATOR_PANIC_URL = '/o';

// URL that Orchestrator redirects to when user comes to Orchestrator but has no active flow
export const ORCHESTRATOR_NO_ACTIVE_FLOW_URL = ORCHESTRATOR_PANIC_URL;

// Mappings between a connection request's flow ID and the translation key to use for i18n strings
export const requestTypeMappings = {
  connectAccountingSystem: 'connectAccountingSystem',
  connectBank: 'connectBank',
  bankAccountKyc: 'bankAccountKyc',
  connectPsp: 'connectPsp',
  'visa-connect-accounting-system': 'connectAccountingSystem',
  'connect-accounting-system-and-bank': 'connectAccountingSystem',
  'connect-accounting-system-and-psp': 'connectAccountingSystem',
  'connect-bank-kyc': 'bankAccountKyc',
  'connect-bank-kyc-details': 'bankAccountKyc',
  default: 'default',
} as Record<string, RequestConnectionType | 'default'>;

export const connectAccountingSystemActions = [
  'connect-accounting-system',
  'connect-accounting-system-bank',
  'connect-accounting-system-psp',
  'connect-accounting-system-mollie-ap-ar',
  'visa-connect-accounting-system',
];
