import { atom } from 'jotai';
import { setCookie } from 'src/utils';
import restApi from 'src/services/restApi';
import { userSessionAtom } from '../user-state-atoms';
import { Session } from 'src/interfaces/Session';
import User from 'src/interfaces/User';
import { setUserValuesAtom } from './setUserValuesAtom';
import { CookieKeys } from 'src/tokens';
import * as Sentry from '@sentry/react';
import { IdentificationLevel } from 'src/types/bizcuitApi';

const setUserSessionAtom = atom(
  null,
  (
    _get,
    set,
    user: Partial<User>,
    userSession: Session,
    accessToken: string,
    sessionMethod?: IdentificationLevel,
  ) => {
    set(userSessionAtom, userSession);
    set(setUserValuesAtom, user);

    setCookie(CookieKeys.accessToken, accessToken as string);

    if (sessionMethod) localStorage.setItem('sessionMethod', sessionMethod);

    Sentry.setUser({ id: user.id });

    restApi.defaults.headers['x-bizcuit-access-token'] = accessToken;
  },
);

export { setUserSessionAtom };
