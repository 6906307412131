import React, { ReactNode, useEffect } from 'react';
import useInvoiceStore from 'src/hooks/useInvoiceStore';
import { invoiceStateAtom, setPersistedInvoiceState } from './invoice-atoms';
import { useGetInvoiceFinanceTokenLazyQuery } from 'src/types/bizcuitApi';
import { globalStore } from '../globalStore';

const InvoiceProvider = ({ children }: { children: ReactNode }) => {
  const {
    state,
    actions: { setFundrApiState },
  } = useInvoiceStore();
  const [
    getInvoiceFinanceToken,
    { data: fundrApiTokenData, error: fundrApiTokenError, loading: fundrApiTokenLoading },
  ] = useGetInvoiceFinanceTokenLazyQuery({
    fetchPolicy: 'network-only',
  });

  // Fundr API access token
  useEffect(() => {
    if (!state.fundrApiState?.accessToken) {
      getInvoiceFinanceToken();
    }
  }, [getInvoiceFinanceToken, state.fundrApiState?.accessToken]);

  useEffect(() => {
    if (fundrApiTokenData?.getInvoiceFinanceToken?.__typename) {
      switch (fundrApiTokenData.getInvoiceFinanceToken.__typename) {
        case 'RaboValidationError':
          {
            setFundrApiState({
              accessToken: '',
              loading: false,
              message: null,
              validationError: fundrApiTokenData.getInvoiceFinanceToken,
            });
          }
          break;

        case 'InvoiceFinanceToken': {
          if (fundrApiTokenData.getInvoiceFinanceToken.accessToken) {
            const { accessToken, caseId } = fundrApiTokenData.getInvoiceFinanceToken;
            setFundrApiState({
              accessToken: accessToken,
              caseId: caseId || '',
              loading: false,
              message: null,
              validationError: null,
            });
          }
        }
      }
    }
  }, [fundrApiTokenData?.getInvoiceFinanceToken, setFundrApiState]);

  useEffect(() => {
    if (fundrApiTokenError) {
      setFundrApiState({
        accessToken: '',
        caseId: '',
        loading: false,
        message: fundrApiTokenError.message,
        validationError: null,
      });
    }
  }, [fundrApiTokenError, setFundrApiState]);

  useEffect(() => {
    setFundrApiState({ loading: fundrApiTokenLoading });
  }, [fundrApiTokenLoading, setFundrApiState]);

  useEffect(() => {
    setPersistedInvoiceState(globalStore.get(invoiceStateAtom));
  }, []);

  return <>{children}</>;
};

export default InvoiceProvider;
