import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { capitalize, getCookie, logger, removeCookie, setCookie } from 'src/utils';
import { CookieKeys, DEFAULT_SUPPORT_EMAIL, Paths } from 'src/tokens';
import { BizcuitError } from 'src/tokens/BizcuitErrors';
import { IdentificationLevel, User, useGetUserSessionLazyQuery } from 'src/types/bizcuitApi';
import Dialog from '../dialog/Dialog';
import { useUserStore, useErrorTranslation, usePartnerStore } from 'src/hooks';
import Paragraph from 'src/atoms/text/paragraph/Paragraph';
import { TextColor } from 'src/atoms/text/text/Text.types';
import Container from 'src/atoms/layout/container/Container';
import ErrorContent from '../dialog/ErrorContent';
import { useAtom } from 'jotai/index';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';
import getTranslation from 'src/helpers/getTranslation';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { EventNames } from 'src/types/analytics';
import { routerBaseName } from 'src/tokens/Paths';

const IDIN_TIMEOUT = 1000 * 60 * 5;
const EMAIL_TIMEOUT = 1000 * 60 * 15;
const EMBEDDED_TIMEOUT = 1000 * 60 * 15;

const PROMPT_BEFORE_IDLE_TIMEOUT = 1000 * 15;
const TIMERS_SYNC_INTERVAL = 100;

const getIdleTimeout = (user?: Partial<User>) => {
  if (user?.embeddedUser) {
    return EMBEDDED_TIMEOUT;
  }
  if (user?.identificationLevel === IdentificationLevel.Email) {
    return EMAIL_TIMEOUT;
  }
  return IDIN_TIMEOUT;
};

const LogoutTimer: React.FC = () => {
  const { t } = useTranslation('common');
  const {
    state: { user, isActiveUser },
  } = useUserStore();
  const {
    state: { partner },
  } = usePartnerStore();
  const [orchestratorState] = useAtom(orchestratorAtom);
  const { resetUserIdentity, trackEvent } = useAnalytics();
  const expiredError = useErrorTranslation('sessionExpired');
  useErrorTranslation('accountNotActive', {
    errorTitle: getTranslation(`accountingHub:${orchestratorState?.flow?.id}.shortTitle`),
  });
  const defaultErrorTranslations = useErrorTranslation('somethingWentWrong');

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState<BizcuitError | null>(null);
  const [remainingPromptTime, setRemainingPromptTime] = useState<number>();
  const promptIntervalRef = useRef<NodeJS.Timer>();
  const cookieIntervalRef = useRef<NodeJS.Timer>();
  const [getUserSession, { data: sessionData, error: sessionError, loading: sessionLoading }] =
    useGetUserSessionLazyQuery({
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (sessionError) {
      logger.error(`Logout due to gql error ${sessionError.message}`);
      trackEvent(EventNames.signedOut, { action: 'sessionError', error: sessionError.message });
      handleLogoutRef.current();
    }
  }, [sessionError, trackEvent]);

  const pollSession = useCallback(async () => {
    if (!sessionLoading) {
      await getUserSession();
    }
  }, [getUserSession, sessionLoading]);

  const handleManualLogout = () => {
    trackEvent(EventNames.signedOut, { action: 'manualLogout' });
    handleLogout();
  };

  const handleLogout = () => {
    setIsDialogOpen(false);
    pauseTimer();
    clearInterval(promptIntervalRef.current);
    clearInterval(cookieIntervalRef.current);
    removeCookie(CookieKeys.accessToken);
    resetUserIdentity();
    const redirectUrl = orchestratorState?.redirectUrl;

    const approverTimeOutRedirectUrl =
      `${routerBaseName}${Paths.orchestrator.root}${Paths.orchestrator.segments.request.root}${Paths.orchestrator.segments.request.segments.details}`.replace(
        ':requestId',
        orchestratorState?.approverRequest?.requestId ?? '',
      );

    const isApprover = user.id === orchestratorState?.approverRequest?.approver?.id;

    if (!isActiveUser() && user.embeddedUser) {
      if (isApprover) {
        setError({
          ...expiredError,
          redirectUrl: approverTimeOutRedirectUrl,
          isErrorMessageWithBlankTitle: true,
        });
        setIsDialogOpen(true);
        return;
      }

      setError({
        ...expiredError,
        ...(redirectUrl && { redirectUrl }),
        isErrorMessageWithBlankTitle: true,
      });
      setIsDialogOpen(true);
    }
  };
  const handleLogoutRef = useRef(handleLogout);

  useEffect(() => {
    if (sessionData?.getUserSession) {
      const accessToken = getCookie(CookieKeys.accessToken);
      if (accessToken) {
        // Refresh cookie to extend expiration time
        setCookie(CookieKeys.accessToken, accessToken);
      } else {
        trackEvent(EventNames.signedOut, {
          action: 'cookieError',
          error: 'No accessToken in pollSession callback',
        });
        handleLogoutRef.current();
      }
    }
  }, [sessionData, trackEvent]);

  const handleStillHere = () => {
    setIsDialogOpen(false);
    activateTimer();
    pollSession();
  };

  const {
    getRemainingTime,
    getElapsedTime,
    activate: activateTimer,
    pause: pauseTimer,
  } = useIdleTimer({
    timeout: getIdleTimeout(user),
    promptBeforeIdle: PROMPT_BEFORE_IDLE_TIMEOUT,
    crossTab: true,
    leaderElection: true,
    syncTimers: TIMERS_SYNC_INTERVAL,
    onPrompt: () => setIsDialogOpen(true),
    onActive: () => setIsDialogOpen(false),
    onIdle: () => handleLogoutRef.current(),
    throttle: 500,
  });

  useEffect(() => {
    promptIntervalRef.current = setInterval(() => {
      const remainingPromptTime = Math.max(0, Math.ceil(getRemainingTime() / 1000));
      const elapsedTimeSeconds = Math.ceil(getElapsedTime() / 1000);

      if (remainingPromptTime <= 1) {
        trackEvent(EventNames.signedOut, { action: 'idleTimeout' });
        handleLogoutRef.current();
        return;
      }

      if (elapsedTimeSeconds % Math.floor(getIdleTimeout(user) / 2000) === 0) {
        pollSession();
      }

      if (remainingPromptTime > PROMPT_BEFORE_IDLE_TIMEOUT / 1000) {
        setIsDialogOpen(false);
      }
      setRemainingPromptTime(remainingPromptTime);
    }, 995);

    return () => {
      clearInterval(promptIntervalRef.current);
    };
  }, [getElapsedTime, getRemainingTime, pollSession, trackEvent, user]);

  useEffect(() => {
    cookieIntervalRef.current = setInterval(() => {
      const isAccessTokenStored = Boolean(getCookie(CookieKeys.accessToken));

      if (!isAccessTokenStored) {
        trackEvent(EventNames.signedOut, { action: 'cookieError', error: 'No accessToken cookie' });
        handleLogoutRef.current();
        clearInterval(cookieIntervalRef.current);
      }
    }, 1500);

    return () => {
      clearInterval(cookieIntervalRef.current);
    };
  }, [trackEvent]);

  const ErrorDialog = () => (
    <Dialog
      isVisible={!!error}
      actions={[
        {
          label: capitalize(t('close')),
          onClick: () => {
            if (error?.redirectUrl) {
              window.location.href = error.redirectUrl;
              return;
            }

            setIsDialogOpen(!isDialogOpen);
          },
        },
      ]}
    >
      <ErrorContent
        mainErrorDescription={error?.message || defaultErrorTranslations.message}
        errorSupportTitle={
          error?.errorSupport?.title || defaultErrorTranslations.errorSupport.title
        }
        errorSupportDescription={
          error?.errorSupport?.description || defaultErrorTranslations.errorSupport.description
        }
        supportEmail={partner?.supportEmail || DEFAULT_SUPPORT_EMAIL}
      />
    </Dialog>
  );

  const TimeoutDialog = () => {
    if (user?.embeddedUser) {
      return null;
    }

    return (
      <Dialog
        isVisible={isDialogOpen}
        actions={[
          {
            label: t('logout'),
            cancel: true,
            onClick: handleManualLogout,
          },
          {
            label: t('stayLoggedIn'),
            onClick: handleStillHere,
          },
        ]}
      >
        <div className="w-full m-auto mt-2 mb-3 sm:w-3/4 md:w-3/4 flex-2">
          <Paragraph className="text-center mb-5" color={TextColor.primary500}>
            {t('logout')}
          </Paragraph>
          <Container>
            <Paragraph className="text-left">
              {t('noActivity', { seconds: remainingPromptTime })}
            </Paragraph>
          </Container>
        </div>
      </Dialog>
    );
  };

  return error && isDialogOpen ? <ErrorDialog /> : <TimeoutDialog />;
};

export default LogoutTimer;
