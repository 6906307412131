import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import PrimaryHeader from 'src/atoms/text/primary-header/PrimaryHeader';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import * as Sentry from '@sentry/react';
import { getLocalStorageObject, parseQueryParamsString, setLocalStorageObject } from 'src/helpers';
import { EmailsSent, PERSISTENT_EMAIL_KEY } from 'src/tokens';
import { useGetCustomers, useStoreKvkNumberManual } from 'src/types/bizcuitApi';
import { useErrorDialog, useUserStore } from 'src/hooks';
import { useLocation } from 'react-router-dom';
import Paths from 'src/tokens/Paths';
import { ORCHESTRATOR_PANIC_URL } from '../../orchestrator/config';
import { useAtom } from 'jotai/index';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';
import { useNavigate } from 'react-router';
import { useOrchestrator } from 'src/hooks/useOrchestrator';

const actionId = 'provide-kvk';

const ProvideKvk = () => {
  const location = useLocation();
  const queryParams = parseQueryParamsString(location.search);
  const [orchestratorState] = useAtom(orchestratorAtom);
  const navigate = useNavigate();

  const orchestrator = useOrchestrator();
  const [sendKvkForm, { loading, error }] = useStoreKvkNumberManual();
  const errorDialog = useErrorDialog();
  const { t } = useTranslation(['cdd', 'common']);
  const [formData, setFormData] = useState({ kvkNumber: '', note: '' });
  const emailsSent = getLocalStorageObject<EmailsSent>(PERSISTENT_EMAIL_KEY);
  const {
    state: { user },
  } = useUserStore();
  const getCustomers = useGetCustomers({
    fetchPolicy: 'cache-and-network',
  });

  const overviewUrl = orchestratorState?.flow?.id
    ? Paths.orchestrator.root +
      Paths.orchestrator.segments.overview.replace(':flowId', orchestratorState.flow.id)
    : ORCHESTRATOR_PANIC_URL;

  if (!queryParams.dynamicId) {
    errorDialog.open();
    navigate(overviewUrl);
  }

  const customer = useMemo(() => {
    return getCustomers.data?.getCustomers?.find(
      (customer) => customer?.id === queryParams.dynamicId,
    );
  }, [getCustomers.data?.getCustomers, queryParams.dynamicId]);

  useEffect(() => {
    if (!getCustomers.error) return;

    errorDialog.open();
    navigate(overviewUrl);
  }, [errorDialog, getCustomers.error, navigate, overviewUrl]);

  const handleConfirm = async () => {
    const { data } = await sendKvkForm({
      variables: {
        kvkNumber: formData.kvkNumber,
        customerId: queryParams.dynamicId,
        messageFields: JSON.stringify({
          kvkNumber: formData.kvkNumber,
          customerId: queryParams.dynamicId,
          customerName: customer?.name,
          ...(formData.note && {
            note: formData.note,
          }),
        }),
      },
    });

    if (error || !data?.sendForm || !data?.storeKvkNumber) {
      Sentry.captureException('Manually provide kvk failed');
      return errorDialog.open();
    }

    if (data?.sendForm) {
      setLocalStorageObject(PERSISTENT_EMAIL_KEY, {
        ...emailsSent,
        [user.id]: { [`kvk_provided`]: true, ...emailsSent?.[user.id] },
      });
    }

    const updatedCustomerId = data.storeKvkNumber.customerId;

    if (!orchestratorState?.flow?.id) {
      navigate(ORCHESTRATOR_PANIC_URL);
      return;
    }

    await orchestrator.methods.finishFlowAction({
      actionId,
      dynamicId: queryParams.dynamicId,
      ...(updatedCustomerId !== queryParams.dynamicId && { updatedDynamicId: updatedCustomerId }),
      shouldNavigate: true,
    });
  };

  const description = t('cdd:kvkNotListed.description', { returnObjects: true });

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex justify-center pb-6">
          <PrimaryButton
            className="md:w-80"
            showSpinner={loading}
            type="submit"
            form="provide-kvk"
            isDisabled={!formData.kvkNumber}
          >
            {t('common:support.sendToSupport')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <PrimaryHeader className="text-warm-grey-800 dark:text-white">
          {t('cdd:kvkNotListed.primaryHeader')}
        </PrimaryHeader>
        <div className="flex flex-col gap-6">
          {description.map((desc, index) => (
            <p key={index} className="text-medium-loose">
              {desc}
            </p>
          ))}
          {!customer ? (
            <div className="animate-pulse w-1/4 h-4 mb-1 bg-primary-500 dark:bg-primary-800 rounded-2xl" />
          ) : (
            <p className="text-medium font-weight-500 text-grey-900 dark:text-white">
              {customer.name}
            </p>
          )}
          <form
            className="flex flex-col gap-6"
            autoComplete="off"
            id="provide-kvk"
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirm();
            }}
          >
            <TextInput
              placeHolder={t('common:typeChamberOfCommerceNumber')}
              label={t('common:chamberOfCommerceNumber')}
              name="kvkNumber"
              onChange={(value) => setFormData((prevState) => ({ ...prevState, kvkNumber: value }))}
              value={formData.kvkNumber}
            />
            <TextInput
              placeHolder={t('common:typeComment')}
              label={t('common:comment')}
              name="comment"
              onChange={(value) => setFormData((prevState) => ({ ...prevState, note: value }))}
              value={formData.note}
            />
          </form>
        </div>
      </>
    </FlowLayout>
  );
};

export default ProvideKvk;
