import { useCallback } from 'react';
import { atom } from 'jotai';
import { useAtom } from 'jotai/index';

const fullPageLoaderAtom = atom(false);

const useFullPageLoader = () => {
  const [isFullPageLoaderVisible, setIsFullPageLoaderVisible] = useAtom(fullPageLoaderAtom);

  const showFullPageLoader = useCallback(
    () => setIsFullPageLoaderVisible(true),
    [setIsFullPageLoaderVisible],
  );
  const hideFullPageLoader = useCallback(
    () => setIsFullPageLoaderVisible(false),
    [setIsFullPageLoaderVisible],
  );

  return {
    isFullPageLoaderVisible,
    showFullPageLoader,
    hideFullPageLoader,
  };
};

export default useFullPageLoader;
