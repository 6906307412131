/**
 * GraphQL operations that are public and do not require authentication
 */
export const publicOperations = [
  'GetPartner',
  'GetPartners',
  'GetBankBic',
  'GetBankAccountBic',
  'GetOrCreateUserSession',
];
