import { OpenApiPaths } from '../MockData';
import { OrchestratorFlowDemoState } from '../useOrchestratorFlowDemo';
import { getPartnerSecret } from '../utils';
import { keysToSnake } from 'src/utils';

type CreateUserDataParams = Pick<OrchestratorFlowDemoState, 'partnerId' | 'flowId' | 'userId'> &
  Partial<
    Pick<
      OrchestratorFlowDemoState,
      | 'extendJwtDuration'
      | 'restrictedAccountingSystemList'
      | 'bankList'
      | 'bankConnectorList'
      | 'flowParams'
      | 'pspList'
    >
  >;

const createRedirectUrl = async ({
  flowId,
  partnerId,
  userId,
  extendJwtDuration,
  restrictedAccountingSystemList,
  bankConnectorList,
  bankList,
  pspList,
  flowParams,
}: CreateUserDataParams) => {
  const response = await fetch(`${OpenApiPaths.initiateUserFlow}${flowId}`, {
    method: 'POST',
    headers: {
      'x-bizcuit-client-id': partnerId,
      'x-bizcuit-client-secret': getPartnerSecret(partnerId),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: userId,
      redirect_url: 'https://bizcuit.nl/',
      ...(extendJwtDuration && { extend_jwt_duration: extendJwtDuration }),
      filter: {
        accounting_systems: {
          ...(restrictedAccountingSystemList?.length && {
            names:
              restrictedAccountingSystemList
                .map((restrictedAccountSystem) => restrictedAccountSystem.toLowerCase().trim())
                .filter((s) => Boolean(s)) || undefined,
          }),
        },
        banks: {
          ...(bankList?.length && {
            names:
              bankList.map((bank) => bank.toLowerCase().trim()).filter((s) => Boolean(s)) ||
              undefined,
          }),
          ...(bankConnectorList?.length && {
            connector_ids:
              bankConnectorList
                ?.map((connectorId) => connectorId.toLowerCase().trim())
                .filter((s) => Boolean(s)) || undefined,
          }),
        },
        psps: {
          ...(pspList?.length && {
            names:
              pspList.map((psp) => psp.toLowerCase().trim()).filter((s) => Boolean(s)) || undefined,
          }),
        },
      },
      ...(flowParams && { params: keysToSnake(flowParams) }),
    }),
  });

  return response;
};

export default createRedirectUrl;
