import { useAtomValue, useSetAtom } from 'jotai';
import {
  bindSessionAtom,
  createInactiveUserAtom,
  createSignUpSessionAtom,
  getUserSubscription,
  isActiveUserAtom,
  postBillingAddressAtom,
  removeBillingAddressAtom,
  sendUserApproveTermsEmail,
  sendUserEmailExistEmailAtom,
  sendVerificationToken,
  setBillingIbanAtom,
  setEmailAtom,
  setNameAtom,
  setUserAtom,
  setUserSingleValueAtom,
  setUserValuesAtom,
  updateSessionAtom,
  userAtom,
  userSessionAtom,
  verifyEmailToken,
  cleanPersistedUserState,
  setUserSessionAtom,
} from 'src/contexts/user-global-state/user-atoms';
import { StoreHookContract } from 'src/types/store';
import { sendVerificationTokenEmail } from 'src/services/user/send-verification-token-email/sendVerificationTokenEmail';

const useUserStore = () => {
  return {
    state: {
      user: useAtomValue(userAtom),
      userSession: useAtomValue(userSessionAtom),
      isActiveUser: useAtomValue(isActiveUserAtom),
    },
    actions: {
      setUserSession: useSetAtom(setUserSessionAtom),
      createSignUpSession: useSetAtom(createSignUpSessionAtom),
      createInactiveUser: useSetAtom(createInactiveUserAtom),
      sendUserEmailExistEmail: useSetAtom(sendUserEmailExistEmailAtom),
      setEmail: useSetAtom(setEmailAtom),
      setName: useSetAtom(setNameAtom),
      postBillingAddress: useSetAtom(postBillingAddressAtom),
      removeBillingAddress: useSetAtom(removeBillingAddressAtom),
      setBillingIban: useSetAtom(setBillingIbanAtom),
      setUser: useSetAtom(setUserAtom),
      setUserValue: useSetAtom(setUserSingleValueAtom),
      setUserValues: useSetAtom(setUserValuesAtom),
      bindSession: useSetAtom(bindSessionAtom),
      getSession: useSetAtom(updateSessionAtom),
      sendVerificationTokenEmail,
      sendVerificationToken,
      verifyEmailToken,
      sendUserApproveTermsEmail,
      getUserSubscription,
      cleanPersistedUserState,
    },
  } satisfies StoreHookContract;
};

export default useUserStore;
