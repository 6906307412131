import { ReactNode, useEffect } from 'react';
import { getCookie, isNotNull } from 'src/utils';
import { useMenu, useNativeCommunication, usePartnerStore, useUserStore } from 'src/hooks';
import {
  getPersistedUserState,
  setPersistedUserState,
  userAtom,
  userSessionAtom,
} from './user-atoms';
import { Theme, useGetPartnerLazyQuery, useGetUserLazyQuery } from 'src/types/bizcuitApi';
import { globalStore } from '../globalStore';
import { CookieKeys } from 'src/tokens';
import { useLocation } from 'react-router-dom';

const UserProvider = ({ children }: { children: ReactNode }) => {
  const {
    state: { user },
    actions: { setUserValues },
  } = useUserStore();

  const {
    actions: { setPartnerState, setPartnerTheme },
  } = usePartnerStore();

  const {
    actions: { setMenuVisible },
  } = useMenu();

  const location = useLocation();
  const [getUser, { data: userData }] = useGetUserLazyQuery();
  const [getPartner, { data: partnerData }] = useGetPartnerLazyQuery();
  const { isNativeApp, setNativeAppUser } = useNativeCommunication();

  useEffect(() => {
    setPersistedUserState({
      user: globalStore.get(userAtom),
      userSession: globalStore.get(userSessionAtom),
    });

    const userData = getPersistedUserState().user;
    if (isNativeApp()) setNativeAppUser(userData);
  }, [setNativeAppUser, isNativeApp]);

  useEffect(() => {
    const token = getCookie(CookieKeys.accessToken);

    if (!token || (location.pathname.includes('embedded-flow') && location.search.includes('code')))
      return;

    getUser({ context: { headers: { Authorization: token } } });
  }, [getUser, location.pathname, location.search]);

  useEffect(() => {
    if (userData?.getUser) {
      const newUser = Object.fromEntries(
        Object.entries(userData.getUser).filter(([k, v]) => k !== '__typename' && v !== null),
      );
      setUserValues(newUser);
      if (userData.getUser.partnerId) {
        getPartner({ variables: { partnerId: userData.getUser.partnerId } });
      }
    }
  }, [getPartner, setUserValues, userData?.getUser]);

  useEffect(() => {
    setMenuVisible(!user.embeddedUser && isNotNull(user.embeddedUser));
  }, [setMenuVisible, user, user.embeddedUser]);

  useEffect(() => {
    if (
      partnerData?.getPartner?.partner &&
      partnerData.getPartner.partner.__typename === 'Partner'
    ) {
      setPartnerState({
        partner: partnerData.getPartner.partner,
        partnerId: partnerData.getPartner.partner.id,
      });
      const theme: Theme = {
        name: partnerData.getPartner.partner.appName,
        icon: partnerData.getPartner.partner.icon,
        ...(partnerData.getPartner.partner.theme && { ...partnerData.getPartner.partner.theme }),
      };
      setPartnerTheme(theme);
    }
  }, [partnerData, partnerData?.getPartner, setPartnerState, setPartnerTheme, setUserValues]);

  return <>{children}</>;
};

export { UserProvider };
