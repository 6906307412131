import './FullPageLoader.css';

const FullPageLoaderBars = () => {
  return (
    <>
      {[...Array(6)].map((_, index) => {
        let loaderBarClass = `loader__bar`;
        if (index > 0) loaderBarClass += ` loader__bar--delay-${index}`;

        return <div key={index} className={`${loaderBarClass} flex flex-1 h-full relative`} />;
      })}
    </>
  );
};

const FullPageLoader = () => {
  return (
    <div className="full-page-loader flex flex-col h-screen w-screen fixed top-0 left-0 z-50">
      <FullPageLoaderBars />
    </div>
  );
};

export default FullPageLoader;
