import { ReactNode, useEffect } from 'react';
import { setPersistedPartnerState } from 'src/contexts/partner-global-state/partner-atoms';
import { globalStore } from '../globalStore';

import {
  partnerAtom,
  partnerIdAtom,
  partnerReferenceAtom,
  brandingPartnerIdAtom,
  partnerAuthorisationAtom,
} from './partner-atoms';

const PartnerProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    setPersistedPartnerState({
      partner: globalStore.get(partnerAtom),
      partnerId: globalStore.get(partnerIdAtom),
      partnerReference: globalStore.get(partnerReferenceAtom),
      brandingPartnerId: globalStore.get(brandingPartnerIdAtom),
      partnerAuthorisation: globalStore.get(partnerAuthorisationAtom),
    });
  }, []);

  return <>{children}</>;
};

export { PartnerProvider };
