import React, { FC } from 'react';
import RadioContainerProps from './RadioContainer.types';

const RadioContainer: FC<RadioContainerProps> = ({
  children,
  name,
  value,
  checked,
  onSelected,
}) => (
  <label className="flex gap-4 items-center relative bg-white dark:bg-biz-gray-800 border border-biz-gray-100 dark:border-biz-gray-900 p-4 rounded-2xl shadow-md cursor-pointer">
    <input
      type="radio"
      onClick={() => onSelected()}
      value={value}
      name={name}
      className="appearance-none absolute"
    />
    <div
      className={`flex items-center justify-center w-5 h-5 rounded-full shrink-0 ${
        checked ? 'border border-primary-500' : 'border-2 border-biz-gray-600'
      }`}
    >
      {checked && <div className="rounded-full bg-primary-500 w-3 h-3" />}
    </div>
    {children}
  </label>
);

export default RadioContainer;
