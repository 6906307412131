import {
  BasicBankAccount,
  DefaultUserData,
  DemoNames,
  VerifiedUserPayloadData,
} from './OrchestratorFlowDemo.types';

export const defaultDemoPartner =
  process.env.NODE_ENV === 'production' ? DemoNames.TestPartner : DemoNames.UniversalPartner;

export const flows = {
  'connect-bank': 'Connect Bank',
  'connect-accounting-system-and-bank': 'Connect Accounting System and Bank',
  'connect-accounting-system': 'Connect Accounting System',
  'connect-accounting-system-mollie-ap-ar': 'Mollie boekhoudkoppeling - Demo',
  'connect-psp': 'Connect PSP',
  'connect-accounting-system-and-psp': 'Connect Accounting System and PSP',
} as const;

export const OpenApiPaths = {
  users: '/openapi/users',
  initiateUserFlow: '/openapi/v2/embedded_flows/initiate_flow/',
  oAuth: '/openapi/v2/oauth',
  token: '/openapi/v2/oauth/token',
  createAdministration: '/openapi/v2/manage_administrations/create',
  createBankAccount: '/openapi/v2/manage_bank_accounts/create',
};

export const mockAdministrations = [
  {
    name: 'Bakery B.V.',
    send_ubl: true,
    kvk_number: '68122853',
  },
  {
    name: 'Shoe Shop B.V.',
    send_ubl: true,
    kvk_number: '87654321',
  },
];

// Mock IBANs are generated with https://www.myfakeinfo.com/creditcard/iban-generator.php
export const mockBankAccounts: BasicBankAccount[] = [
  {
    iban: 'NL10SNSB8845592065',
    display_name: 'Bizcuit B.V.',
    currency: 'EUR',
  },
  {
    iban: 'NL09CARD9000014321',
    display_name: 'Sales & Representative',
    currency: 'EUR',
  },
  {
    iban: 'NL84CARD9000001234',
    display_name: 'General spends',
    currency: 'EUR',
  },
  {
    api_account: true,
    display_name: 'Mollie Account',
    currency: 'EUR',
  },
];

export const mockVerifiedUserData = {
  user: {
    partner_reference: 'partner_customer_1',
    initials: 'J.J.',
    first_name: 'Jan',
    last_name: 'Jansen',
    terms_accepted: false,
    billing_accepted: true,
    verified_user: true,
    date_of_birth: '1970-01-01',
    company_name: 'Jansen B.V.',
    phone_number: '0612345678',
    embedded_user: true,
    email: null,
    external_id: 'test-6k60vvow7z7',
  },
  bizcuit_customer: {
    business_account: true,
    billing_address: {
      name: 'Bizcuit B.V',
      street: 'Lunet 3a',
      street2: '',
      postal_code: '3905 NW',
      city: 'Veenendaal',
      province: 'Utrecht',
      country: 'Netherlands',
    },
    iban: 'NL10SNSB8845592065',
  },
  administration: mockAdministrations[0],
  bank_account: mockBankAccounts[0],
} satisfies VerifiedUserPayloadData;

export const mockUserData = mockVerifiedUserData.user;

export const defaultPartnerId = '21318431-ff11-4e05-94ab-686c6b12c8bb';

export const defaultUserData: DefaultUserData = {
  [DemoNames.UniversalPartner]: {
    userId: '',
    email: '',
    icon: 'https://dev.bizcuit.nl/assets/bizcuit-logo.png',
    validFlows: [
      'connect-accounting-system-and-bank',
      'connect-accounting-system',
      'connect-bank',
      'connect-accounting-system-and-psp',
      'connect-psp',
    ],
    partnerId: '20354d7a-2222-3333-4444-555555555555',
    partnerSecret: '20354d7a-bbbb-cccc-dddd-eeeeeeeeeeee',
    prodOnly: false,
  },
  [DemoNames.TestPartner]: {
    userId: '',
    email: '',
    icon: 'https://bizcuit-partners-partnerlogosbucket-11kontpjbj60c.s3.eu-west-1.amazonaws.com/66486037-4675-4f3f-b304-ce52648eb7dd',
    validFlows: ['connect-accounting-system-and-bank'],
    partnerId: '65efdb1a-7d3f-4f99-9e31-835d42268a08',
    partnerSecret: '8ebd0a20-8fc9-4b7b-a1df-ca1e4f626941',
    prodOnly: true,
  },
  [DemoNames.SnsBoekhoudkoppelingAcc]: {
    userId: '',
    email: '',
    icon: 'https://tst.bizcuit.nl/assets/sns-boekhoudkoppeling-logo.png',
    validFlows: ['connect-accounting-system-and-bank'],
    partnerId: '8237355b-3b4b-40d6-9ef6-f09fad306c6f',
    partnerSecret: 'abbc4610-4e00-49e0-8807-273f6eddf5ba',
    prodOnly: false,
    bankConnectors: 'sns_commercial',
  },
  [DemoNames.RegioBankBoekhoudkoppelingAcc]: {
    userId: '',
    email: '',
    icon: 'https://tst.bizcuit.nl/assets/regiobank-boekhoudkoppeling-logo.png',
    validFlows: ['connect-accounting-system-and-bank'],
    partnerId: '8266698a-dac1-456c-9f61-b92330573902',
    partnerSecret: '4c449352-11ef-46be-a854-a55c4cb3d1fc',
    prodOnly: false,
    bankConnectors: 'regiobank_commercial',
  },
  [DemoNames.AsnBoekhoudkoppelingAcc]: {
    userId: '',
    email: '',
    icon: 'https://tst.bizcuit.nl/assets/asn-boekhoudkoppeling-logo.png',
    validFlows: ['connect-accounting-system-and-bank'],
    partnerId: '84ef9dfc-d229-48ff-9e53-a71e2019acb2',
    partnerSecret: 'c4bb3b5d-35b7-474d-8634-f607ba9a4b32',
    prodOnly: false,
    bankConnectors: 'asn_commercial',
  },
  [DemoNames.SnsBoekhoudkoppelingProd]: {
    userId: '',
    email: '',
    icon: 'https://app.bizcuit.nl/assets/sns-boekhoudkoppeling-logo.png',
    validFlows: ['connect-accounting-system-and-bank'],
    partnerId: 'c27763fa-21fa-4423-92bc-c47178bb7b35',
    partnerSecret: '82016b88-c1e7-443f-925c-e5e5a61e648c',
    prodOnly: true,
    bankConnectors: 'sns_commercial',
  },
  [DemoNames.RegioBankBoekhoudkoppelingProd]: {
    userId: '',
    email: '',
    icon: 'https://app.bizcuit.nl/assets/regiobank-boekhoudkoppeling-logo.png',
    validFlows: ['connect-accounting-system-and-bank'],
    partnerId: '0bbcb288-9a84-4e62-9a9e-ec6a46e70ab1',
    partnerSecret: '46d9bef8-94e3-4045-8a84-4c5c868f75c2',
    prodOnly: true,
    bankConnectors: 'regiobank_commercial',
  },
  [DemoNames.AsnBoekhoudkoppelingProd]: {
    userId: '',
    email: '',
    icon: 'https://app.bizcuit.nl/assets/asn-boekhoudkoppeling-logo.png',
    validFlows: ['connect-accounting-system-and-bank'],
    partnerId: '455ed97f-5644-4069-bae3-578108a82415',
    partnerSecret: '98b2b1c0-49a7-43cb-9f53-5c179cbcc83a',
    prodOnly: true,
    bankConnectors: 'asn_commercial',
  },
  [DemoNames.ConnectAccountingSystemMollieApAr]: {
    userId: '',
    email: '',
    icon: '/assets/mollie-logo-2023.svg',
    validFlows: ['connect-accounting-system-mollie-ap-ar'],
    partnerId: 'de4f560a-afb9-4e9e-a48f-d1656108e5e2',
    partnerSecret: '347933ca-1f09-42a7-9619-43ba75b68f2f',
    prodOnly: false,
  },
};

export const RenewBankConsentFlows = {
  'renew-bank-consent': 'Renew Bank Consent',
  'renew-bank-consent-email': 'Renew Bank Consent via E-mail',
} as const;
