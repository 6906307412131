import { withProfiler } from '@sentry/react';
import { DevTools } from 'jotai-devtools';
import { FC, Suspense, useCallback, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthRouter from 'src/AuthRouter';
import ApolloClientProvider from './contexts/apollo-context/ApolloClientProvider';
import { AppsProvider } from './contexts/apps-global-state/AppsProvider';
import InvoiceProvider from './contexts/invoice-global-state/InvoiceProvider';
import { PartnerProvider } from './contexts/partner-global-state/PartnerProvider';
import { getPersistedPartnerState } from './contexts/partner-global-state/partner-atoms';
import { UserProvider } from './contexts/user-global-state/UserProvider';
import { AnalyticsProvider } from './hooks/useAnalytics';
import { ErrorDialogProvider } from 'src/hooks/useErrorDialog';
import RaboInvoiceFinancingRouter from './pages/rabo-invoice-financing/RaboInvoiceFinancingRouter';
import HixRouter from './pages/hix/HixRouter';
import { DialogProvider } from './organisms/dialog/Dialog';
import LogoutTimer from './organisms/logout-timer/LogoutTimer';
import AppContainer from './atoms/layout/app-container/AppContainer';
import MainContainer from './atoms/layout/main-container/MainContainer';
import RedirectToExternalSource from './atoms/redirect-to-external-source/RedirectToExternalSource';
import Paths, { routerBaseName } from './tokens/Paths';
import { usePartnerStore } from './hooks';
import AisRouter from './pages/ais/AisRouter';
import OrchestratorFlowDemo from './pages/orchestratorFlowDemo/OrchestratorFlowDemo';
import OrchestratorRouter from './pages/orchestrator/OrchestratorRouter';
import ConnectMiniAppRouter from './pages/connect-mini-app/ConnectMiniAppRouter';
import AuthorizeInFlowEmbeddedUser from './pages/authorize-user/AuthorizeInFlowEmbeddedUser';
import { PageLayout } from './atoms/layout/menu-page';
import OrchestratorGuard from './organisms/orchestrator-guard/OrchestratorGuard';
import PrivateRoutesGuard from './organisms/private-routes-guard/PrivateRoutesGuard';
import TermsAcceptedGuard from './organisms/terms-accepted-guard/TermsAcceptedGuard';
import { LocalStorageKeys } from './tokens';
import RenewBankConsentDemo from './pages/orchestratorFlowDemo/RenewBankConsentDemo';
import CddRouter from './pages/cdd/CddRouter';
import MockBank from 'src/pages/mock-bank/MockBank';
import FullPageLoader from 'src/atoms/loaders/full-page-loader/FullPageLoader';
import useFullPageLoader from 'src/hooks/useFullPageLoader';
import { globalStore } from 'src/contexts/globalStore';
import { Provider } from 'jotai';

const AppFullPageLoader = () => {
  const { isFullPageLoaderVisible } = useFullPageLoader();

  if (!isFullPageLoaderVisible) return null;

  return <FullPageLoader />;
};

const App: FC = () => {
  const {
    actions: { setPartnerTheme },
  } = usePartnerStore();

  const resetPartnerTheme = useCallback(() => {
    const persistedPartner = getPersistedPartnerState();

    if (!persistedPartner?.partner?.theme) return;

    setPartnerTheme(persistedPartner?.partner.theme);
  }, [setPartnerTheme]);

  useEffect(() => {
    resetPartnerTheme();
  }, [resetPartnerTheme]);

  const updateThemeMode = (event: MediaQueryListEvent | MediaQueryList) => {
    const appearanceMode = localStorage.getItem(LocalStorageKeys.appearanceMode);
    if (appearanceMode && ['dark', 'light'].includes(appearanceMode)) {
      appearanceMode === 'dark'
        ? document.documentElement.classList.add('dark')
        : document.documentElement.classList.remove('dark');
    } else {
      if (event.matches) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  };

  const mql = window.matchMedia('(prefers-color-scheme: dark)');
  updateThemeMode(mql);
  mql.addEventListener('change', (event) => {
    updateThemeMode(event);
  });

  return (
    <Suspense fallback={null}>
      <Provider store={globalStore}>
        <AppFullPageLoader />
        <HelmetProvider>
          <Router basename={routerBaseName}>
            <ApolloClientProvider>
              <PartnerProvider>
                <UserProvider>
                  <AnalyticsProvider>
                    <DialogProvider>
                      <ErrorDialogProvider>
                        <Routes>
                          <Route
                            path={'/*'}
                            element={
                              <AppContainer>
                                <AuthRouter />
                              </AppContainer>
                            }
                          />
                          <Route
                            path={Paths.logInV1.root}
                            element={
                              <RedirectToExternalSource
                                url={`${
                                  process.env.NODE_ENV === 'production'
                                    ? process.env.REACT_APP_LEGACY_APP_URL + '#ref=sessionExpired'
                                    : 'http://localhost/#ref=sessionExpired'
                                }`}
                              />
                            }
                          />
                          <Route
                            path="/app/home"
                            element={
                              <RedirectToExternalSource
                                url={`${process.env.REACT_APP_LEGACY_APP_URL}/app/home`}
                              />
                            }
                          />
                          <Route
                            path={`${Paths.orchestrator.root}${Paths.orchestrator.segments.orchestratorFlowDemo}`}
                            element={<OrchestratorFlowDemo />}
                          />
                          <Route
                            path={`${Paths.orchestrator.root}${Paths.orchestrator.segments.renewBankConsentDemo}`}
                            element={<RenewBankConsentDemo />}
                          />
                          <Route
                            path={`${Paths.embeddedFlow.root}`}
                            element={
                              <MainContainer>
                                <PageLayout>
                                  <AuthorizeInFlowEmbeddedUser />
                                </PageLayout>
                              </MainContainer>
                            }
                          />
                          <Route
                            path={`${Paths.ais.root}${Paths.ais.segments.cdd.root}/*`}
                            element={<CddRouter />}
                          />
                          <Route element={<PrivateRoutesGuard />}>
                            <Route element={<OrchestratorGuard />}>
                              <Route path={`${Paths.ais.root}/*`} element={<AisRouter />} />
                              <Route element={<TermsAcceptedGuard />}>
                                <Route
                                  path={`${Paths.miniApp.root}/*`}
                                  element={
                                    <MainContainer>
                                      <LogoutTimer />
                                      <Routes>
                                        <Route path="/*" element={<ConnectMiniAppRouter />} />
                                        <Route path="/hix/*" element={<HixRouter />} />
                                        <Route
                                          path={'/rabo-invoice-financing/*'}
                                          element={
                                            <InvoiceProvider>
                                              <AppsProvider>
                                                <RaboInvoiceFinancingRouter />
                                              </AppsProvider>
                                            </InvoiceProvider>
                                          }
                                        />
                                      </Routes>
                                    </MainContainer>
                                  }
                                />
                              </Route>
                              <Route
                                path={`${Paths.orchestrator.root}/*`}
                                element={<OrchestratorRouter />}
                              />
                            </Route>
                            <Route path={'/mock_bank_psd2/auth'} element={<MockBank />} />
                          </Route>
                        </Routes>
                      </ErrorDialogProvider>
                    </DialogProvider>
                    <ToastContainer />
                  </AnalyticsProvider>
                </UserProvider>
              </PartnerProvider>
            </ApolloClientProvider>
          </Router>
        </HelmetProvider>
        {process.env.NODE_ENV === 'development' ? <DevTools /> : null}
      </Provider>
    </Suspense>
  );
};

export default withProfiler(App);
