import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import PrimaryHeader from 'src/atoms/text/primary-header/PrimaryHeader';
import { useTranslation } from 'react-i18next';
import KvkSearch from 'src/organisms/kvk-search/KvKSearch';
import { CompanyItem, useGetCustomers, useStoreKvkNumber } from 'src/types/bizcuitApi';
import { useNavigate } from 'react-router';
import Paths from 'src/tokens/Paths';
import { useOrchestrator } from 'src/hooks/useOrchestrator';
import { useLocation } from 'react-router-dom';
import { parseQueryParamsString } from 'src/helpers';
import { useAtom } from 'jotai';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';
import { ORCHESTRATOR_PANIC_URL } from 'src/pages/orchestrator/config';
import { useErrorDialog } from 'src/hooks';
import SkeletonProvideKvkLoader from 'src/atoms/loaders/skeleton-loader/SkeletonProvideKvkLoader';

const actionId = 'provide-kvk';

const ProvideKvkLayout = ({
  children,
  handleConfirm,
  handleNotListed,
  canContinue,
}: {
  children: ReactNode;
  handleConfirm: () => Promise<void>;
  handleNotListed?: () => void;
  canContinue: boolean;
}) => {
  const { t } = useTranslation(['cdd', 'common']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await handleConfirm();
    setIsSubmitting(false);
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex pb-6 items-center w-full flex-wrap flex-col">
          <SecondaryButton underline onClick={handleNotListed}>
            {t('cdd:provideKvk.secondaryButton')}
          </SecondaryButton>
          <PrimaryButton
            className="md:w-80"
            onClick={handleSubmit}
            showSpinner={isSubmitting}
            isDisabled={!canContinue}
          >
            {t('common:button.confirm')}
          </PrimaryButton>
        </footer>
      }
    >
      {children}
    </FlowLayout>
  );
};

const ProvideKvk = () => {
  const { t } = useTranslation(['cdd', 'common']);
  const [kvkResult, setKvkResult] = useState<CompanyItem>();
  const [companyAmount, setCompanyAmount] = useState<number>(0);
  const orchestrator = useOrchestrator();
  const [orchestratorState] = useAtom(orchestratorAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = parseQueryParamsString(location.search);
  const [storeKvkNumber] = useStoreKvkNumber();
  const errorDialog = useErrorDialog();
  const getCustomers = useGetCustomers({
    fetchPolicy: 'cache-and-network',
  });
  const overviewUrl = orchestratorState?.flow?.id
    ? Paths.orchestrator.root +
      Paths.orchestrator.segments.overview.replace(':flowId', orchestratorState.flow.id)
    : ORCHESTRATOR_PANIC_URL;
  const customerId = queryParams.dynamicId;

  if (!customerId) {
    navigate(overviewUrl);
  }

  const customer = useMemo(() => {
    return getCustomers.data?.getCustomers?.find((customer) => customer?.id === customerId);
  }, [getCustomers.data?.getCustomers, customerId]);

  useEffect(() => {
    if (getCustomers.loading) return;

    if (getCustomers.error || !customer) {
      errorDialog.open();
      navigate(overviewUrl);
    }
  }, [customer, errorDialog, getCustomers.error, getCustomers.loading, navigate, overviewUrl]);

  const handleConfirm = async () => {
    if (!kvkResult?.kvkNumber) {
      errorDialog.open();
      return;
    }

    const storeKvkNumberResult = await storeKvkNumber({
      variables: { customerId, kvkNumber: kvkResult?.kvkNumber },
    });

    if (storeKvkNumberResult.errors || !storeKvkNumberResult.data?.storeKvkNumber) {
      errorDialog.open();
      return;
    }

    const updatedCustomerId = storeKvkNumberResult.data.storeKvkNumber.customerId;

    if (!orchestratorState?.flow?.id) {
      navigate(ORCHESTRATOR_PANIC_URL);
      return;
    }

    await orchestrator.methods.finishFlowAction({
      actionId,
      dynamicId: customerId,
      ...(updatedCustomerId !== customerId && { updatedDynamicId: updatedCustomerId }),
      shouldNavigate: true,
    });
  };

  const multipleCompaniesFound = companyAmount > 1;
  const descriptionTranslation = multipleCompaniesFound
    ? 'cdd:provideKvk.descriptionMultipleFound'
    : 'cdd:provideKvk.description';

  if (getCustomers.loading || !customer) {
    return (
      <ProvideKvkLayout handleConfirm={handleConfirm} canContinue={false}>
        <SkeletonProvideKvkLoader />
      </ProvideKvkLayout>
    );
  }

  return (
    <ProvideKvkLayout
      handleConfirm={handleConfirm}
      canContinue={Boolean(kvkResult)}
      handleNotListed={() =>
        navigate(
          `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.provideManually}?dynamicId=${customerId}`,
        )
      }
    >
      <>
        <PrimaryHeader className="text-warm-grey-800 dark:text-white">
          {t('cdd:provideKvk.primaryHeader')}
        </PrimaryHeader>
        <div className="flex flex-col gap-6">
          <p className="text-medium-loose">{t(descriptionTranslation)}</p>
          <p className="text-medium font-weight-500 text-grey-900 dark:text-white">
            {customer.name}
          </p>
          <KvkSearch
            onSelected={(value) => setKvkResult(value)}
            companyCount={(amount) => setCompanyAmount(amount)}
            initialSearch={customer.kvkNumber ? customer.kvkNumber : customer.name}
            showCreateAccount={false}
          />
        </div>
      </>
    </ProvideKvkLayout>
  );
};

export default ProvideKvk;
